@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@300;400;600&display=swap');

body,
#root {
    font-family: 'Noto Sans Thai', sans-serif;
    overflow-x: hidden;
    height: 100%;
    width: auto;
}

html {
    scroll-behavior: smooth;
}

/* MyKeyboard.css */
.pinthaid .hg-button {
    background-color: transparent;
    /* Change button background color */
    color: #151617;
    font-size: 20px;
    font-weight: 700;
    /* Change button text color */
    border-radius: 100%;
    /* Round button edges */
    border: 1px solid #00000026;
    font-size: 18px;
    height: 62px;
    /* Increase button font size */
}

.pinthaid .hg-button:not([data-skbtn="_"]):hover {
    background-color: #2e7d32;
    opacity: 0.8;
    color: white;
    /* Change color on hover */
}

.pinthaid .hg-row {
    margin-bottom: 10px;
    gap: 33px
        /* Increase spacing between rows */
}

.pinthaid .hg-rows {
    display: flex;
    flex-direction: column;
    gap: 33px
}

.pinthaid {
    background-color: transparent;
    /* Set the background color of the entire keyboard */
}

.hg-button[data-skbtn="{bksp}"] {
    width: 20px;
    font-size: 22px;
    border: none;
    box-shadow: none;
}

.hg-button[data-skbtn="_"] {
    color: white;
    width: 20px;
    border: none;
    box-shadow: none;
    pointer-events: none;
}