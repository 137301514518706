.swiper-wrapper {
    box-sizing: inherit !important;
}

.swiper-container {
    padding-bottom: 50px;
}

.swiper-pagination-bullet {
    background: white !important;
}

.swiper-pagination-bullet-active {
    background: linear-gradient(180deg, #61F805 0%, #50AC19 100%) !important;
}